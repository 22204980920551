<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ isBookingCenter() ? "登記預約中心" : "預約管理" }}
          </b-breadcrumb-item>

          <b-breadcrumb-item v-if="bookingPreset.name" :to="isBookingCenter() ? { name: 'BookingCenterBookingList', params: { id: bookingPreset.id }} : { name: 'BookingList', params: { id: bookingPreset.id }}">
            {{ bookingPreset.name }}
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{
              isEditing ? '編輯預約' : '新增預約'
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-12">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- begin: dynamic form -->
              <div>
                <h4 class="mb-2 mb-xl-0 font-weight-bold">{{ isEditing ? "編輯預約" : "新增預約" }} - {{ bookingPreset.name }}</h4>
                <b-form-group v-bind:label="'預約會員'" label-cols-sm="3" label-cols-lg="2" content-cols-lg="9" style="width: 100%">
                  <div v-if="customer" class="d-flex align-items-center py-2 staff-info">
                    <b-avatar
                      class="mx-2"
                      :src="customer.avatar_url"
                    ></b-avatar>
                    <div
                      class="
                        mr-auto
                        font-weight-bold
                        text-nowrap text-truncate
                      "
                    >
                      {{ customer.name }}
                      <span class="staff-list-info">
                        {{ customer.email }}<br>
                        {{ displayCustomerInfo(customer) }}
                      </span>
                    </div>
                  </div>
                  <StaffAddCustomer
                    ref="staffAddCustomer"
                    :add-button-text="addCustomerButtonText"
                    @bind="handleSelectCustomer"
                    :sourceType="'line'"
                  ></StaffAddCustomer>
                </b-form-group>
                <!-- wizard 模式 start-->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="分店"
                  label-for="staff"
                  v-if="bookingPreset.type == 'wizard'"
                >
                  <b-select
                    class="mr-3"
                    :options="branchOptions"
                    :disabled="disabledBranchSelect"
                    v-model="booking.branch_id"
                  ></b-select>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="員工"
                  label-for="staff"
                  v-if="bookingPreset.type == 'wizard'"
                >
                  <div class="d-flex">
                    <b-form-text
                      v-if="booking.staff"
                      class="mb-2 mr-sm-2 mb-sm-0"
                    >
                      {{ booking.staff.name }}
                    </b-form-text>
                    <b-button
                      class="mb-xl-0"
                      variant="primary"
                      @click="showAddStaff = true"
                      ><i class="fa fa-plus"></i>
                      選擇員工
                    </b-button>
                  </div>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="服務"
                  label-for="service"
                  v-if="bookingPreset.type == 'wizard'"
                >
                  <b-form-checkbox-group v-model="booking.service_id">
                    <b-form-checkbox
                      v-for="(option, optionIndex) in serviceOptions"
                      :key="optionIndex"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
                <!-- wizard 模式 end-->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="開始時間"
                  label-for="start_at"
                  v-if="false === hasSetUpFieldMappingForStartAt"
                >
                  <AppDateTimePicker id="start_at" v-model="start_at" :hasError="deepGet(v$, 'start_at.$error')"></AppDateTimePicker>
                  <b-form-invalid-feedback :state="!deepGet(v$, 'start_at.$error')">
                    輸入的資料有誤或未填寫，請確認
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="結束時間"
                  label-for="end_at"
                  v-if="false === hasSetUpFieldMappingForEndAt"
                >
                  <AppDateTimePicker id="end_at" v-model="end_at" :hasError="deepGet(v$, 'end_at.$error')"></AppDateTimePicker>
                  <b-form-invalid-feedback :state="!deepGet(v$, 'end_at.$error')">
                    輸入的資料有誤或未填寫，請確認
                  </b-form-invalid-feedback>
                </b-form-group>
                <DynamicFormDashboard
                  ref="dynamicForm"
                  :input-subjects="visibleSubjects"
                  v-model="inputForm"
                ></DynamicFormDashboard>
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center mt-4">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </div>
              <!-- end: dynamic form -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->
    <SelectStaff
      :show-add-staff="showAddStaff"
      :branchId="booking.branch_id"
      @selected="handleStaffSelected"
      @close="showAddStaff = false"
    ></SelectStaff>
  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import bookingApi from "@/apis/booking";
import AppDateTimePicker from "@/components/AppDateTimePicker"
import DynamicFormDashboard from "@/components/DynamicForm/DynamicFormDashboard"
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import StaffAddCustomer from "@/pages/Dashboard/Staff/StaffAddCustomer.vue";
import _ from "lodash";
import { mapGetters } from "vuex";
import SelectStaff from "@/components/Dashboard/Staff/SelectStaff.vue"
import collectionApi from "@/apis/collection";

export default {
  components: {
    AppDateTimePicker,
    DynamicFormDashboard,
    StaffAddCustomer,
    SelectStaff
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  validations() {
    return {
      start_at: this.hasSetUpFieldMappingForStartAt ? {} : { required },
      end_at: this.hasSetUpFieldMappingForEndAt ? {} : { required },
    };
  },
  data() {
    return {
      consts,
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      showAddStaff: false,
      step: '',
      serviceOptions: [],
      bookingPreset: {
        name: null,
        config: {
          fields: [],
        }
      },
      start_at: null,
      end_at: null,
      inputForm: {},
      booking: {
        customer_id: null,
        service_id: [],
        staff_id: null,
        branch_id: null,
        start_at: null,
        end_at: null,
        data: []
      },
      customer: null,
      disabledBranchSelect: false
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("route", ["routeQuery", "routeParams"]),
    ...mapGetters('general', [
      'myBranches',
    ]),
    addCustomerButtonText() {
      return this.customer ? "重新選擇會員" : "選擇此預約所屬會員";
    },
    hasSetUpFieldMappingForStartAt() {
      return _.get(this.bookingPreset, 'config.booking_mapping.bookings.start_at', null) !== null;
    },
    hasSetUpFieldMappingForEndAt() {
      return _.get(this.bookingPreset, 'config.booking_mapping.bookings.end_at', null) !== null;
    },
    branchOptions() {
      let options = this.myBranches
        .map(branch => {
          let branchName = `${branch.branch_code} ${branch.name}`
          return {
            value: branch.id,
            text: branchName
          }
        })
      options = [
        {
            value: null,
            text: '請選擇分店'
        },
        ...options
      ]

      if (!options.find((o) => o.value == this.booking.branch_id)) {
        options.push({
          value: this.booking.branch_id,
          text: this.booking.branch.name
        })
      }
      return options
    },
    subjects() {
      return this.bookingPreset.config.fields.map((field) => {
        return {
          id: field._id ?? field.config._id, // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
          ...field,
          ...field.config,
        }
      })
    },
    visibleSubjects() {
      return this.bookingPreset.config.fields.filter(this.passesVisibleCondition)
    },
  },
  methods: {
    deepGet: _.get,
    isBookingCenter() {
      return this.$route.name.includes("BookingCenter");
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    async init () {
      this.bookingPreset.id = this.$route.params.id;
      await this.fetchBookingPreset();
      if (["BookingEdit", "BookingCenterBookingEdit"].includes(this.$route.name)) {
        this.isEditing = true;
        this.booking.id = this.$route.params.booking_id;
        await this.fetchBooking();
      } else {
        this.booking.branch_id = this.$route.query.branch_id;
        if (this.booking.branch_id) {
          this.disabledBranchSelect = true;
        }
        this.isEditing = false;
      }
    },
    handleStaffSelected(staff) {
      this.booking.staff_id = staff.id;
      this.booking.staff = staff;
    },
    cancel() {
      if (this.isBookingCenter()) {
        this.$router.push({ name: 'BookingCenterBookingList', params: {id: this.bookingPreset.id} });
      } else {
        this.$router.push({ name: 'BookingList', params: this.routeParams, query: this.routeQuery});
      }
    },
    async submit() {
      // 先驗證 vuelidate 的字段
      const isVuelidateValid = await this.v$.$validate();

      // 如果 vuelidate 驗證不通過，退出函數並顯示錯誤
      if (!isVuelidateValid) {
        const element = document.getElementById(this.v$.$errors[0].$property);
        element.scrollIntoView({ behavior: "smooth" });
        console.warn("[BookingForm] Validation failed.");
        return;
      }

      const validate = await this.$refs.dynamicForm.validate();
      if (!validate) return;

      try {
        this.showLoading = true;

        // 將 item.data 組起來
        let data = [];

        for (let i = 0; i < this.bookingPreset.config.fields.length; i ++) {
          const field = this.bookingPreset.config.fields[i];
          const fieldId = field._id ?? field.config._id; // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦

          data.push({
            field_id: fieldId,
            title: field.title,
            value: this.inputForm[fieldId] ?? null,
          });
        }

        const bookingDataToSave = {
          data,
          customer_id: this.customer ? this.customer.id : null,
        };

        if (false === this.hasSetUpFieldMappingForStartAt) {
          bookingDataToSave.start_at = this.start_at;
        }

        if (false === this.hasSetUpFieldMappingForEndAt) {
          bookingDataToSave.end_at = this.end_at;
        }

        if (this.bookingPreset.type == 'wizard') {
          bookingDataToSave.staff_id = this.booking.staff_id;
          bookingDataToSave.branch_id = this.booking.branch_id;
          bookingDataToSave.service_id = this.booking.service_id;
        }

        // 編輯表單
        if (this.isEditing) {
          await bookingApi.updateBooking(this.bookingPreset.id, this.booking.id, bookingDataToSave);
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          await bookingApi.createBooking(this.bookingPreset.id, bookingDataToSave);
          this.$swal("新增成功", "", "success");
          this.cancel();
        }
        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBookingPreset() {
      if (! this.bookingPreset.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await bookingApi.getBookingPreset(this.bookingPreset.id);

        if (! response.data.data.config) {
          response.data.data.config = {
            fields: [],
          };
        }

        this.bookingPreset = response.data.data;

        if (!this.bookingPreset.config?.fields || this.bookingPreset.config.fields.length === 0) {
          this.$swal("提醒", "此預約尚未設定欄位，須設定欄位後才能新增及編輯預約", "warning");
          this.cancel();
          return;
        }

        this.bookingPreset.config.fields = this.bookingPreset.config.fields.map((field) => {
          return {
            id: field._id ?? field.config._id, // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
            ...field,
            ...field.config,
          }
        });

        if (this.bookingPreset.config?.service?.collection_id) {
          const fieldId = this.bookingPreset.config?.service?.title_item_id;
          const collectionData = await collectionApi.getCollection(this.bookingPreset.config.service.collection_id);
          const items = collectionData.data.data.items
          if (items) {
            this.serviceOptions = items.map((item) => {
              return {
                value: item.id,
                text: item.data.find((d) => d.field_id == fieldId)?.value,
              }
            })
          }
        }

        let editInputForm = {};

        for (let i = 0; i < this.bookingPreset.config.fields.length; i ++) {
          const field = this.bookingPreset.config.fields[i];
          const fieldId = field._id ?? field.config._id; // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
          editInputForm[fieldId] = null;
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBooking() {
      try {
        this.showLoading = true;
        const response = await bookingApi.getBooking(this.bookingPreset.id, this.booking.id);
        this.booking = response.data;

        let editInputForm = {};

        for (let i = 0; i < this.bookingPreset.config.fields.length; i ++) {
          const field = this.bookingPreset.config.fields[i];
          const fieldId = _.get(field, '_id') ?? _.get(field, 'config._id'); // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
          const fieldData = this.booking.data.find((data) => data.field_id === fieldId)?.value ?? null;

          if (field.type === "checkbox") {
            editInputForm[fieldId] = Array.isArray(fieldData) ? fieldData : [fieldData].filter(Boolean);
          } else {
            editInputForm[fieldId] = fieldData;
          }
        }

        this.start_at = this.booking.start_at;
        this.end_at = this.booking.end_at;
        this.customer = this.booking.customer;

        if (this.booking.service_id) {
          this.booking.service_id = JSON.parse(this.booking.service_id)
        } else {
          this.booking.service_id = []
        }

        this.inputForm = editInputForm;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async handleSelectCustomer(customer) {
      this.$swal({
        title: "確定要加入會員嗎",
        html: `
          <div class="d-block">
            <div class="my-3 text-left">
              <div>會員姓名:${customer.name}</div>
            </div>
          </div>`,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "返回",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "確認加入",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            this.customer = customer;
          } catch (e) {
            console.error(e)
          } finally {
            this.$refs.staffAddCustomer.refreshSearch()
            this.$refs.staffAddCustomer.closeModal()
          }
        }
      })
    },
    displayCustomerInfo(customer) {
      return ((customer.outer_code) ? customer.outer_code + " " : "") + (customer.mobile_phone ?? "");
    },
    passesVisibleCondition(subjectOrSectionOrPage) {
      const anyConditions = _.get(subjectOrSectionOrPage, 'visible_when_any', []);
      if (anyConditions.length === 0) {
        return true;
      }
      return anyConditions.some(condition => this.subjects.some(possibleSubject => {
        return condition.field_id === possibleSubject.id
          && condition.value === this.inputForm[possibleSubject.id];
      }));
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon-for-done {
    display: none;
  }

  &.done {
    i {
      color: green;
    }

    .icon-for-done {
      display: unset;
    }

    .icon-for-undone {
      display: none;
    }
  }
}
</style>
